import { Skeleton, Td, Tr } from '@chakra-ui/react';
import _ from 'lodash';

export const TablePlaceholder = () => {
  return (
    <>
      {_.map(new Array(10), () => {
        return (
          <Tr key={`row-skeleton-${Math.random()}`}>
            {_.map(new Array(9), () => {
              return (
                <Td key={`row-skeleton-${Math.random()}`}>
                  <Skeleton height={'1rem'} />
                </Td>
              );
            })}
          </Tr>
        );
      })}
    </>
  );
};
